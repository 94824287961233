<template>
	<v-container
		class="mx-0 px-0"
	>
		<v-row no-gutters>
			<v-col cols="12" md="auto" class="title mb-1">
				<span 
					@click="$router.push('/new-order')"
				>
					Vehicle Information
				</span>
			</v-col>
			<v-col class="title mb-1">
				<span class="mx-2">
					<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
				</span>
				<span class="font-weight-bold black--text">Select Seat Model</span>
			</v-col>
		</v-row>
		<template v-if="isReady">
			<v-row
				no-gutters
				class="pa-0"
				style="height: calc(100vh - 240px); width: 99vw"
				justify="center"
			>
				<v-col
					cols="12" md="12"
					class="align-self-center"
					
				>
					<v-carousel 
						v-model="seatSelection"
						hide-delimiters
						height="100%"				
						@change="resizeEngine()"
					>
						<v-carousel-item
							v-for="(currentModel,index) in option_model_code"
							:key="index"
							class="text-center py-2"
							reverse-transition="fade-transition"
							transition="fade-transition"
						>
							
							<template v-if="currentModel.glbSeat">
								<template class="text-center font-weight-bold pt-6">
									<span class="black--text"> {{ currentModel.dk_code }} </span>
								</template>
								<SeatLoader
									ref="seatLoader"
									:data-key="index"
									:item="currentModel"
									@seat-ready="()=>{
										isLoadedSeat = true;
										//renderPackage()
									}"
								></SeatLoader>
							</template>
							<template v-else><span class="black--text">No GBL file </span></template>
						</v-carousel-item>
					</v-carousel>
					<!-- <div 
						v-for="currentModel in option_model_code"
						:key="currentModel.id"
						class="text-center pa-2"
						style="border:1px solid" 
					>
						<div v-if="currentModel.glbSeat">
							{{ currentModel.glbSeat.path }}
						</div>
						<div v-else>No GBL file</div>
						<div class="text-center font-weight-bold pt-6">
							{{ currentModel.dk_code }}
						</div>
					</div> -->
				</v-col>
			</v-row>
			<v-row justify="end">
				<v-col cols="12" md="4">
					<v-btn
						block
						depressed
						color="primary"
						class="text-capitalize secondary--text radius-10"
						:disabled="!option_model_code[seatSelection].glbSeat"
						@click="proceedCustomization()"
					>
						Proceed to Customization
					</v-btn>
				</v-col>
			</v-row>
		</template>
		<template v-else>
			<div class="text-center headline py-16">
				<v-icon size="150">mdi-alert</v-icon>
				<div>Seat Not Found</div>
				<v-btn
					class="mt-3 px-12"
					outlined
					@click="$router.push('/new-order')"
				>
					Back
				</v-btn>
			</div>
		</template>
		<v-overlay
			:value="!isLoadedSeat"
			style="z-index: 21;"
		>
			<v-progress-circular 
				indeterminate 
				size="96"
			>
				Loading...
			</v-progress-circular>
		</v-overlay>
	</v-container>
	
</template>

<script>
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { resourceMixin } from "@/mixins/ResourceMixin"
import { searchMixin } from "@/mixins/SearchMixin"
import SeatLoader from "@/components/SeatLoader"
import { mapGetters, mapActions } from "vuex"

const FILE_TYPE_GLB = 10

export default {
	components:{
		SeatLoader,
	},
	mixins: [
		resourceMixin,
		searchMixin,
		errorHandlerMixin,
	],
	data(){
		return {
			option_model_code: [],
			form: {},
			seatSelection: 0,
			isReady: false,
			isLoadedSeat: false,
		}
	},
	computed: {
		...mapGetters(["selectedSeat"]),
	},
	created(){
		this.init()
	},
	methods:{
		...mapActions(["setIsCustomization", "setSelectedSeatModel", "setOrderCustomizations"]),
		init(){
			this.form = this.selectedSeat
			this.getModelCodeOption()
		},
		proceedCustomization(){
			this.setOrderCustomizations({})
			this.setSelectedSeatModel(this.option_model_code[this.seatSelection])
			this.setIsCustomization(true)
			this.$router.push('/new-order/customization')
		},
		getModelCodeOption(){
			let payload = {
				maker_id: this.form.make,
				model_name: this.form.model_name,
				year : this.form.year,
				seater_id : this.form.seater_number,
				region_id: this.form.region,
				glb_only: 1,
				is_archived: 0,
			}
			if(this.form.theModel)
				payload.dk_code = this.form.theModel.dk_code
				
			this.model_loading_ = true
			this.$api.getSDList(payload).then((res)=>{
				let { data, total } = res.data.result
				this.option_model_code = data
				this.option_model_code.forEach((item)=>{
					item.glbSeat = item.file_uploads.find((file)=> file.file_upload_type_id == FILE_TYPE_GLB)
				})
				this.isReady = true
				this.initSeat()
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
		selectedPackage(index,seat,val){
			let relatedSockets = seat.sd_socket.filter((item)=> item.package_id == val.value)
			this.$refs.seatLoader[index].setPackageSockets(relatedSockets)
		},
		resizeEngine()
		{
			this.initSeat()					
		},
		initSeat()
		{
			if(this.option_model_code[this.seatSelection].glbSeat)
			{
				let _this = this
				setTimeout(function() { 
					if(!_this.$refs.seatLoader[_this.seatSelection].chair)
					{
						_this.isLoadedSeat = false
						let seat = _this.option_model_code[_this.seatSelection]
						_this.selectedPackage(_this.seatSelection,seat,{ value: 2,  title: "Full Leather", })
						_this.$refs.seatLoader[_this.seatSelection].loadChair( seat.glbSeat.path.replace(process.env.VUE_APP_BUCKET_DOMAIN, ''))
					}}, 1000);
			}
			else
				this.isLoadedSeat = true	
		}
	}
}
</script>
